import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import PlanDetails from '../../../../signup/components/plan-details'
import VoucherMessages from '../../../../signup/components/voucher-messages'
import { useStyles } from './payment-method-header.styles'
import { usePlanChangeFlag } from '../../../../../hooks/usePlanChange'
import { usePriceChangeAlertFlag } from '../../../../../hooks/usePriceChangeAlert'
import PaymentPriceChangeAlert from '../../../../shared/payment-price-change-alert/payment-price-change-alert'
import { paymentAlertMessage } from '../../../../shared/subscription-constants'

const PaymentMethodHeader = ({
  copy,
  promotionRejected,
  detail,
  isAnnuallyPlan,
  isStandardPlan,
  showVoucherMessage
}) => {
  const styles = useStyles()
  const getPlanDetails = usePlanChangeFlag()
  const showPriceChangeAlert = usePriceChangeAlertFlag()
  const paymentCopy = getPlanDetails?.annual.paymentCopy

  return (
    <div className={styles.container}>
      <h1 className={styles.title} data-e2e="payment-method-header-add-payment">
        Add payment details
      </h1>

      <p className={styles.copy}>
        {copy}
      </p>

      <p className={classNames(styles.copy, styles.alertMessage)}>
        {paymentAlertMessage}
      </p>

      <PlanDetails detail={detail} promotionRejected={promotionRejected} />

      {showPriceChangeAlert && <PaymentPriceChangeAlert isStandardPlan={isStandardPlan} isAnnuallyPlan={isAnnuallyPlan} />}

      {isAnnuallyPlan && (
        <>
          <div
            className={styles.annualInfo}
            data-e2e="payment-method-header-annual-plan"
          >
            {paymentCopy}
          </div>
        </>
      )}

      {showVoucherMessage && <VoucherMessages />}

      <div className={styles.divider} />
    </div>
  )
}

PaymentMethodHeader.propTypes = {
  copy: PropTypes.string.isRequired,
  promotionRejected: PropTypes.bool.isRequired,
  detail: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  isAnnuallyPlan: PropTypes.bool.isRequired,
  isStandardPlan: PropTypes.bool.isRequired,
  showVoucherMessage: PropTypes.bool.isRequired
}

export default PaymentMethodHeader
