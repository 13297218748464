import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/react-hooks'
import {
  path,
  pathEq
} from 'ramda'
import { format } from 'date-fns'
import Button from '../../forms/neon/button'
import styles from './cancel.css'
import { E2E_SELECTORS } from '../../../e2e-selectors'
import { LoadingModal } from '../../loading/modal'
import ACCOUNT_QUERY_WITH_DIR from '../../../../graphql/queries/account-with-directives.gql'
import REMOVE_SUBSCRIPTION_MUTATION from '../../../../graphql/mutations/remove-subscription.gql'
import { SUBSCRIPTION_STATUS } from '../../../constants'

const isSuspendedAccount = pathEq(['status'], SUBSCRIPTION_STATUS.SUSPENDED)

const SubscriptionCancel = ({
  onRequestClose,
  saveHasSeenRenewalPrompt,
  accountId
}) => {
  const [activeSubscription, setActiveSubscription] = useState(null)

  // Get current subscription from Evergent by forcing `withClearCachedSubscriptions: true`
  const {
    data: dataAccount,
    loading: loadingAccount,
    error: errorAccount
  } = useQuery(ACCOUNT_QUERY_WITH_DIR, {
    fetchPolicy: 'network-only',
    variables: {
      withSubscription: true,
      withClearCachedSubscriptions: true
    }
  })

  // Remove subscription mutation
  const [
    removeSubscription,
    { loading: loadingRemove, data: dataRemove, error: errorRemove }
  ] = useMutation(REMOVE_SUBSCRIPTION_MUTATION, {
    refetchQueries: [
      {
        query: ACCOUNT_QUERY_WITH_DIR,
        variables: {
          withSubscription: true
        }
      }
    ],
    awaitRefetchQueries: true
  })
  const remove = () => {
    saveHasSeenRenewalPrompt(accountId)
    removeSubscription({
      variables: {
        sku: upcomingSku || sku
      }
    })
  }

  // Update active subscription state to show details in the modal
  useEffect(() => {
    setActiveSubscription(path(['account', 'subscription'], dataAccount))
  }, [dataAccount])

  // Modal details from activeSubscrition
  const endDate = path(['currentSubscription', 'endsAt'], activeSubscription)
  let endDateFormatted
  if (endDate) {
    const [day, month, year] = endDate.split('/')
    endDateFormatted = format(
      new Date(year, month - 1, day),
      'd MMMM yyyy'
    )
  }
  const isSuspended = isSuspendedAccount(activeSubscription)
  const sku = path(['currentSubscription', 'sku'], activeSubscription)
  const upcomingSku = path(['upcomingSubscription', 'sku'], activeSubscription)

  // If mutation was success, close the modal
  useEffect(() => {
    if (!loadingRemove && dataRemove && dataRemove.removeSubscription) {
      onRequestClose()
    }
  }, [loadingRemove, dataRemove])

  // Loading subscription or waiting for mutation response
  if (loadingAccount || loadingRemove) {
    return <LoadingModal />
  }

  return (
    <div className={styles.cancelModal}>
      <h1 className={styles.header}>What to expect when you cancel</h1>
      {isSuspended ? (
        <>
          <div className={styles.body}>
            If you cancel now, you’ll lose any promotions currently applied to your plan.
            <br />
            Remember you can rent Movies with Neon any time before or after you cancel your plan.
          </div>
        </>
      ) : (
        <>
          <div className={styles.body}>
            If you cancel now you will have access to your plan until
            <span className={styles.endDate}>
              {' '}
              {endDateFormatted}
            </span>
            .
            <br />
            You will lose any promotions currently applied to your
            plan.
            <br />
            <br />
            Remember, you can rent movies with Neon at any time before or
            after this date.
          </div>

          {(errorAccount || errorRemove || (dataRemove && !dataRemove.removeSubscription)) && (
            <div className={styles.body}>
              Couldn&apos;t remove plan, please try again.
            </div>
          )}
        </>
      )}

      <div className={styles.buttonRow}>
        <Button
          onClick={remove}
          wrapperClassName={styles.buttonWrapper}
          className={styles.button}
          e2eIdentifier={E2E_SELECTORS.CONFIRM_CANCEL_PLAN}
          text="Yes, Cancel my plan"
        />
        <Button
          secondary
          onClick={onRequestClose}
          wrapperClassName={styles.buttonWrapper}
          className={styles.button}
          text="No, Keep my plan"
        />
      </div>
    </div>
  )
}

SubscriptionCancel.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  saveHasSeenRenewalPrompt: PropTypes.func.isRequired,
  accountId: PropTypes.string.isRequired
}

export default SubscriptionCancel
