import qs from 'query-string'
import {
  useState,
  useEffect,
  useCallback
} from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useLazyQuery } from '@apollo/react-hooks'
import { path } from 'ramda'

import { useSubscriptionIframe } from '../../shared/hooks/use-subscription-iframe'
import { modalContent, paymentCopy, planType } from '../../shared/subscription-constants'

import SUBSCRIPTION_PAYMENT_VIEW_QUERY
  from '../../../../graphql/queries/subscription-payment-view.gql'
import { MODALS, MW_ERRORS, ACCOUNT_SCREENS } from '../../../constants'
import { getModalLocation } from '../../../lib/modal'
import { getGQLErrorCode } from '../../../lib/apollo'
import { usePlanChangeFlag } from '../../../hooks/usePlanChange'
import { usePlanDetails } from '../../shared/hooks/use-plan-details'
import { segmentTrackInitiatePurchase } from '../../../segment/segment-track'

/**
 * hook for fetching and providing data to
 * the subscription payment modal content.
 */
/* eslint-disable import/prefer-default-export */
export const useSubscriptionModalPayment = () => {
  const [loading, setLoading] = useState(true)
  const [serverData, setServerData] = useState({
    iframeUrl: '',
    details: []
  })

  const location = useLocation()
  const history = useHistory()
  const getPlanDetails = usePlanChangeFlag()
  const { getPlanDetailByTitle } = usePlanDetails()

  const { voucher, plan } = qs.parse(location.search)
  const isAnnuallyPlan = plan === planType.ANNUAL
  const isStandardPlan = plan === planType.STANDARD
  const isBasicPlan = plan === planType.BASIC

  const [getPaymentDetails] = useLazyQuery(SUBSCRIPTION_PAYMENT_VIEW_QUERY, {
    onCompleted: result => {
      const data = {
        iframe: path(['subscriptionPaymentView', 'iframe'], result),
        details: path(
          ['subscriptionPaymentView', 'details'],
          result
        )
      }
      setServerData(data)

      // Add segment data analytics for initiating payment
      const selectedPlan = getPlanDetailByTitle(plan)
      const sku = selectedPlan?.sku
      const paymentDue = data?.details[0]?.value
      const payment = Number(data?.details[1]?.value?.replace('$', ''))
      segmentTrackInitiatePurchase({
        ...selectedPlan,
        sku,
        paymentDue,
        payment,
        voucher
      })
    },
    onError: error => {
      pushToError(getGQLErrorCode(error))
    }
  })

  /**
   * Change modal content to error content
   * @param errorCode
   */
  const pushToError = (errorCode) => {
    if (errorCode === MW_ERRORS.USER_ALREADY_HAVE_ACTIVE_SUBSCRIPTION) {
      // Redirect user to /my-account/subscription page as user has completed the payment
      history.push(ACCOUNT_SCREENS.SUBSCRIPTION_PAYMENT)
      return
    }

    const errorContent = getModalLocation(location, MODALS.qsParams.addSubscription, {
      content: modalContent.error,
      error: errorCode
    })
    history.push(errorContent)
  }

  const onSuccess = useCallback(orderId => {
    history.push({
      ...location,
      search: qs.stringify({
        ...qs.parse(location.search),
        orderId,
        content: modalContent.confirmation
      })
    })
  }, [])

  const { iframeRef, promotionRejected } = useSubscriptionIframe(onSuccess)

  /**
   * Get details from middleware and set it for plan section
   */
  const setPaymentDetails = () => {
    let subscriptionId = ''
    if (isAnnuallyPlan) {
      subscriptionId = getPlanDetails?.annual.id
    } else if (isStandardPlan) {
      subscriptionId = getPlanDetails?.standard.id
    } else if (isBasicPlan) {
      subscriptionId = getPlanDetails?.basic.id
    }

    getPaymentDetails({
      variables: {
        subscriptionId,
        voucher,
        promotions: true
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    })
  }

  useEffect(() => {
    setPaymentDetails()
    setLoading(false)
  }, [])

  const getPaymentCopy = () => {
    if (voucher || (plan === planType.STANDARD)) return paymentCopy.standard
    if (isAnnuallyPlan) return paymentCopy.annual
    if (isBasicPlan) return paymentCopy.basic
    return paymentCopy.standard
  }

  const copy = promotionRejected || getPaymentCopy()

  let staticDetail = null
  if (isAnnuallyPlan) {
    staticDetail = getPlanDetails?.annual.staticDetail
  } else if (isStandardPlan) {
    staticDetail = getPlanDetails?.standard.staticDetail
  } else if (isBasicPlan) {
    staticDetail = getPlanDetails?.basic.staticDetail
  }

  const detail = [
    staticDetail,
    ...serverData.details
  ]

  return {
    copy,
    detail,
    loading,
    iframeRef,
    promotionRejected,
    iframeUrl: serverData.iframe,
    showVoucherMessage: Boolean(voucher),
    isAnnuallyPlan,
    isStandardPlan
  }
}
