import {
  compose,
  setDisplayName,
  branch,
  renderComponent,
  withState
} from 'recompose'
import { withRouter } from 'react-router-dom'
import { withSubscriptionPaymentViewLifeCycle } from './step-three-handler'
import {
  withSubscriptionPaymentViewQuery,
  withAccountQueryWithDir,
  withAccountCreditCardsQuery,
  withSubscriptionMutation
} from './graphql'

import SubscriptionPaymentView from '../../../../../components/modals/add-voucher/neon/step-three/subscription-payment-view'

import { LoadingModal } from '../../../../../components/loading/modal'

const enhance = compose(
  setDisplayName('PaymentViewContainer'),
  withRouter,
  withState('savedOrderId', 'setSavedOrderId', ''),
  withState('selectedCardPaymentId', 'setSelectedCardPaymentId', null),
  withState('isSelectedCardPicker', 'setIsSelectedCardPicker', true),
  withState('validCreditCards', 'setValidCreditCards', []),
  withState('subscriptionLoading', 'setSubscriptionLoading', false),
  withSubscriptionPaymentViewQuery,
  withAccountQueryWithDir,
  withAccountCreditCardsQuery,
  withSubscriptionMutation,
  withSubscriptionPaymentViewLifeCycle,
  branch(
    ({
      paymentViewLoading, paymentViewError, iframe, accountCreditCardsLoading, accountCreditCardsError
    }) => (
      paymentViewLoading || paymentViewError || iframe == null || accountCreditCardsLoading || accountCreditCardsError
    ),
    renderComponent(LoadingModal)
  )
)

export default enhance(SubscriptionPaymentView)
